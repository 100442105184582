*{
    padding: 0;
    margin: 0;
}
button{
    border:0px;
    cursor: pointer;
    background: none;
}
*:focus {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

@font-face {
    font-family: Avenir;
    src: url(../assets/fonts/Avenir.ttc);
}
body{
    font-family: 'Avenir Book';
}
a,
a:hover {
    text-decoration: none;
}
.c-blue{
    color: #0074C5;
}
.btn-blue,
.bg-blue{
    background-color: #0074C5;
}
.btn-celeste2{
    background-color: #45BFE4;
}
.btn-celeste{
    background-color: #80BFE6;
}
.bg-celeste,
.btn-celeste2:hover,
.btn-celeste:hover,
.btn-blue:hover{
    background-color: #4DA6DE;
}
.btn-color1{
    background-color: #525C93;
}
.btn-color1:hover{
    background-color: #333D72;
}
.btn-color2{
    background-color: #A5804E;
}
.btn-color2:hover{
    background-color: #816135;
}
.btn-color3{
    background-color: #3D573A;
}
.btn-color3:hover{
    background-color: #294026;
}
.btn-color4{
    background-color: #a3370c;
}
.btn-color4:hover{
    background-color: #812b09;
}
.btn-boletas {
    color: #555555;
    border: 1px solid #555555 !important;
    margin: 8px;
}
.btn-grisDark {
    background-color: #555555;
    border: 1px solid #555555 !important;
}
.btn-grisDark:hover {
    background-color: #373435;
    border: 1px solid #373435 !important;
}
.btn-grisDark2 {
    color: #555555;
    border: 1px solid #555555 !important;
    background: #FFFFFF;
}
textarea {
    width: 100%; 
    color: #495057;
    border: 1px solid #ced4da !important;
}
.btn-grisDark2:hover {
    background-color: #373435;
    border: 1px solid #373435;
    color: #fff;
}
.c-grisDark {
    color: #373435;
}
.c-grisLigth{
    color: #AAAAAA;
}
.bg-grayLigth{
    background-color: #F4F4F4;
    height: 100vh;  
}
.bg-grayLigth2{
    background-color: #F4F4F4;
}
.anteTitulo{
    font-size: 19px;
    font-family: 'Avenir Black';
}
.titleFixed{
    position: fixed;
    bottom: 30px;
    left: 40px;
    z-index: 3;
}
.paddingLeft{
    padding-left: 100px;
}
.paddingTop{
    padding-top: 100px;
}
.paddingTop2{
    padding-top: 60px;
}
.subTituloInterno{
    font-size: 20px;
    font-family: 'Avenir Black';
}
.titulo{
    font-size: 25px;
    font-family: 'Avenir Black';
}
.titulo-2{
    font-size: 20px;
    font-family: 'Avenir Black';
}
.subtitulo{
    font-size: 18px;
    font-family: 'Avenir Book';
}
.image-update{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 2;
    height: 100vh;
}
.image-principal-2{
    background-image: url('../assets/images/bg-02.jpg');
}
.image-principal-1{
    background-image: url('../assets/images/bg-01.jpg'); 
}
.logotipo{
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
}
.logotipoHeader{
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.questions p{
    margin: 0;
    padding-left: 10px;
}
.btnAdd{
    color: #373435;
    text-decoration: underline;
}
.btnAdd:hover{
    color: #000; 
}
.boxQuestion{
    margin-bottom: 15px;
}
.brBoxs{
    border: 1px solid #ced4da;
    border-radius: 7px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 30px;
    margin: 0px;
}
.questions{
    background-color: #8E8E8E;
    width: 100%;
    border-radius: 10px;
    color: #FFF;
    margin-bottom: 12px;
}
.checkboxAll {
    padding: 0px 15px;
}
.franja-azul{
    border-top: 16px solid  #0074C5;
    border-bottom: 16px solid  #0074C5;
    z-index: 1;
}
.form label{
    font-family: 'Avenir Book';
    font-size: 16px;
    padding-bottom: 5px;
    display: block;
}
textarea,
.form input{
    padding: 8px 15px;
    border-radius: 10px;
}
.imgBlock {
    opacity: 0.3;
}
.form input::placeholder{
    color: #AAAAAA;
    font-size: 16px;
}
.btn-Home-Solidez-Boletas{
    padding: 12px 10px !important;
    border: 0;
    cursor: pointer;
    border-radius: 10px;
    width: 268px;
    height: 70px;
    font-size: 18px;
    line-height: 22px;
    float: left;
}
.btn-Home-Solidez{
    padding: 12px 40px !important;
    border: 0;
    cursor: pointer;
    border-radius: 10px;
    width: 250px;
    height: 70px;
    font-size: 18px;
    line-height: 22px;
    position: relative;
}
.btn-Solidez{
    padding: 12px 40px !important;
    border: 0;
    cursor: pointer;
    border-radius: 10px;
}
.btn-Solidez-2{
    padding: 10px 30px !important;
    border-radius: 100px !important;
    border: 0;
    cursor: pointer;
}
.btn-Solidez-3{
    padding: 5px 20px !important;
    border-radius: 100px !important;
        cursor: pointer;
        cursor: pointer;
}
.powered{
    position: fixed;
    bottom: 0px;
    right: 25px;
    font-size: 14px !important;
}

.c-link{
    color: #373435;
    font-family: 'Avenir Book';
    font-size: 16px;
}
.c-link-cerrar{
    color: #373435;
    font-family: 'Avenir Book';
    font-size: 14px;
}
.c-link:hover{
    color: #555555;
}
.show_password {
    position: absolute;
    top: 37px;
    right: 0;
}
.showeyes {
    background-image: url(../assets/images/showeyes.svg);
    height: 16px;
    width: 18px;
    display: block;
    background-repeat: no-repeat;
}
.hideeyes {
    background-image: url(../assets/images/hideeyes.svg);
    height: 16px;
    background-position-x: -1px;
    width: 18px;
    display: block;
    background-repeat: no-repeat;
}
.imputsCamposSolidez select{
    background: url(./../assets/images/down.svg) right/15px no-repeat;
    -webkit-appearance: none;
    background-position-x: 95%;
    outline: 0;
    width: 100%;
}
.parrafo{
    font-family: 'Avenir Book';
    font-size: 16px;
}
.name-user{
    font-family: 'Avenir Book';
    font-size: 20px;
}
.name-admin{
    font-family: 'Avenir Black';
    font-size: 20px;
}
.botonesHome{
    display: contents;
    justify-content: center;
}
.botonesHome a {
    padding: 20px 40px;
    margin: 15px;
    display: grid;
    align-items: center;
}
.fotoFooter{ 
    padding: 10px;
    width: 100%;
    display: flex;
    background-color: #E2E2E2;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
} 
.iconTabla {
    width: 20px;
}
.iconTabla2 {
    width: 40px;
}
.iconMenu{
    position: absolute;
    left: 10px;
    top: 27px;
    z-index: 3;
}
.imputsCampos2,
.imputsCampos{
    margin-top: 10px;
}
.imputsCamposSolidez select,
.imputsCampos2 input,
.imputsCampos2 select,
.imputsCampos input,
.imputsCampos select{
    border: 1px solid #ced4da;
    border-radius: 7px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 30px;
    margin-right: 10px;
    color: #686868;
    background-color: #fff;
}
.imputsCampos2 input,
.imputsCampos2 select{
    width: 230px;
    margin-right: 20px;
}
.imputsCampos input.inputFecha,
.imputsCampos2 input.inputFecha{
    background: url(../assets/images/calendar.svg) right/15px no-repeat;
    -webkit-appearance: none;
    background-position-x: 95%;
    outline: 0;
}
.imputsCampos select,
.imputsCampos2 select{
    background: url(../assets/images/down.svg) right/15px no-repeat;
    -webkit-appearance: none;
    background-position-x: 95%;
    outline: 0;
}
.imputsCampos label,
.imputsCampos2 label{
    color: #373435;
    font-family: 'Avenir Book';
    font-size: 15px;
    margin-right: 10px;
    padding-top: 4px;
}
.imputsCampos2 label{
    min-width: 120px;
}
.imputsCampos3 label{
    min-width: auto;
}
.iconosExcel{
    width: 36px;
}
.iconosPdf{
    width: 22px;
}
.subtitleDescargar{
    font-size: 15px;
}
.titleUnidad {
    float: left;
}
.iconosRecarga {
    float: right;
}
.columna1 .cabeTable{
    height: 95%;
    border-radius: 20px;
    padding-top: 65%;
}
.cabeTable{
    border-radius: 50px;
    padding: 8px 0;
    color: #FFF; 
    text-align: center;
    margin-top: 10px;
}
.cabeTable p{
    margin-bottom: 0px;
}
.columna1{
    width: 90px;
}
.columna2{
    width: 420px;
    margin-left: 25px;
}
.columna3 {
    width: 465px;
    margin: 0 auto;
}
.columna3 .cabeTable{
    padding-right: 25px;
}
.cabeceraTable,
.cabeceraTable2{
    display: -webkit-box; 
    margin: 0 auto;
    font-size: 15px;
    width: fit-content;
}
.txtResultado{
    font-family: 'Avenir Black';
    font-size: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
}
 
.cuperoTable tr td:first-child{
    min-width: 100px;
}
.cuperoTable2 tr td:first-child{
    min-width: 170px;
    text-align: left;
    padding: 0px 20px;
}
 
.cuperoTable2 tr td:last-child{
    text-align: right;
    padding: 0px 20px; 
}

.cuperoTable3 th{
    line-height: 20px;
    padding: 13px !important;
    color: #FFF;
}
.cuperoTable30 th{
    line-height: 20px;
    padding: 13px !important;
    color: #FFF;
}
.cuperoTable4 td,
.cuperoTable4 th{
    padding: 10px 60px !important;
}
.cuperoTable4 .iconTabla{
    width: 17px;
}
.cuperoTable3 th,
.cuperoTable3 td{
    text-align: center;
    padding: 6px 44px;
    font-size: 15px;
    border-right: 1px solid #D0D0D0;
}
.cuperoTable30 th,
.cuperoTable30 td{
    text-align: center;
    padding: 5px 8px;
    font-size: 15px;
    border-right: 1px solid #D0D0D0;
}
.cuperoTable3 th:last-child,
.cuperoTable3 td:last-child{
    border-right:0px;
}
.cuperoTable30 th:last-child,
.cuperoTable30 td:last-child{
    border-right:0px;
}
.cuperoTable td,
.cuperoTable2 td{
    width: 140px;
    min-width: 148px;
    text-align: center;
    padding: 10px 0px;
}
.cuperoTable tr,
.cuperoTable2 tr{
    -webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;

}
table tr td:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
    -webkit-border-radius: 10px 0px 0px 10px;
    -moz-border-radius: 10px 0px 0px 10px;
}
table tr td:last-child {
    border-radius: 0px 10px 10px 0px;
    -webkit-border-radius: 0px 10px 10px 0px;
    -moz-border-radius: 0px 10px 10px 0px;
}
.cuperoTable tr:nth-child(even),
.cuperoTable2 tr:nth-child(even),
.cuperoTable3 tr:nth-child(even)  {
    background: #F5F5F5;
    -webkit-border-radius: 0px 10px 10px 0px; 
    -moz-border-radius: 0px 10px 10px 0px;  
    border-radius: 0px 10px 10px 0px;
   
}
.cuperoTable30 tr:nth-child(even)  {
    background: #F5F5F5;
    -webkit-border-radius: 0px 10px 10px 0px; 
    -moz-border-radius: 0px 10px 10px 0px;  
    border-radius: 0px 10px 10px 0px;
   
}
.cuperoTable tr:nth-child(odd),
.cuperoTable2 tr:nth-child(odd),
.cuperoTable3 tr:nth-child(odd) {
    background: #FFF;
}
.cuperoTable30 tr:nth-child(odd) {
    background: #FFF;
}
.cuperoTable tr:last-child{
    font-family: 'Avenir Black';
    background: #E6E6E6;
    -webkit-border-radius: 0px 100px 100px 0px; 
    -moz-border-radius: 0px 100px 100px 0px;  
    border-radius: 0px 100px 100px 0px;
}
.cuperoTable3 thead tr:last-child{
    font-family: 'Avenir Medium';
    background: #555555;
    border-radius: 100px;
}
.cuperoTable30 thead tr:last-child{
    font-family: 'Avenir Medium';
    background: #555555;
    border-radius: 100px;
}
.descargas{
    width: 90%;
    margin: 0 auto;
    display: flow-root;
}
.descargas2{
    width: 90%;
    margin: 0 auto;
    display: block;
    text-align: center;
}
.mb-100{
    margin-bottom: 100px;
}
.franjaFooter {
    border-bottom: 16px solid #0074C5;
}
.cuperoTable tr td:nth-child(3),
.cuperoTable tr td:nth-child(6),
.cuperoTable2 tr td:nth-child(2) {
    border-left: 1px solid #D0D0D0;
    border-right: 1px solid #D0D0D0;
}
.cabeceraTable table,
.cabeceraTable2 table{
    margin: 0 auto;
}
table th:nth-child(1){
    -webkit-border-radius: 10px 0px 0px 10px; 
    -moz-border-radius: 10px 0px 0px 10px;  
    border-radius: 10px 0px 0px 10px; 
}
table th:nth-last-child(1){ 
    -webkit-border-radius: 0px 10px 10px 0px; 
    -moz-border-radius: 0px 10px 10px 0px;  
    border-radius: 0px 10px 10px 0px;
}
.modal .modal-content{
    border-radius: 20px;
    border: 0px;
}
.iconoModal{
    width: 65px;
    margin: 0px auto 20px auto;
    display: block;
}
#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
   
}
.overlayUp {
    opacity: 1 !important;
    z-index: 9999 !important; 
    top:0px !important;
    background-color: #000000B3;
    cursor: pointer;
}
.closeMenu {
    float: right;
    top: 10px;
    right: 10px;
    position: absolute;
    cursor: pointer;
}
.menuMobile nav ul li a:hover,
.titleMenu{
    font-family: 'Avenir Black';
}
.menuMobile {
    position: fixed;
    padding: 20px;
    top: 0;
    height: 100%;
    width: 300px;
    left: -300px; 
    overflow-y: scroll;
    transform: all 2s ease;
    -webkit-transform: all 2s ease;
    z-index: 999999;
    background-color: #FFFFFF;
}
.menuMobile nav ul li{
    list-style-type: none;
}
.menuMobile nav ul li a{
    color: #555555;
    padding: 8px 8px 0px 0px;
    display: block;
}
.leftMargin1{
    animation-name: leftMargin1;
    animation-duration: 1s;
    animation-fill-mode:both;
}
@keyframes leftMargin1 {
    0% {left:-800px;}
    100% {left: 0px;}
}
.leftMargin2{
    animation-name: leftMargin2;
    animation-duration: 1s;
    animation-fill-mode:both;
}
@keyframes leftMargin2 {
    0% {left:0px;}
    100% {left:-800px;}
}
 
@media (max-width: 1199px) {
    .logotipo{
        width: 180px; 
    }  
    .titulo{
        font-size: 23px; 
    }
    .form{
        padding: 0px;
    }
    .botonesHome{
        display: inline-block;
        width: 100%;
        justify-content: left;
    }
    .botonesHome a { 
        margin: 20px auto; 
        width: 90%;
    }
    .subtitulo{
        font-size: 15px;
    }
    .logotipoHeader {
        width: 100%;
        padding-top: 5px;
    }
    .fotoFooter{
        width: 100%;
        position: relative;
        display: contents;
    }
    .franjaFooter{
        border-bottom: 16px solid  #0074C5;
    }
    .imputsCampos label{
        width: 100px;
    }
    .imputsCampos2 input,
    .imputsCampos2 select,
    .imputsCampos input,
    .imputsCampos select{
     
        width: 200px;
    }
    .imputsCampos .labelFecha-1{
        margin-left: 20px;
    }
    .btn-Solidez-2{
        padding: 10px 16px;
    }
    .cabeceraTable,
    .cabeceraTable2{ 
        overflow: hidden; 
        padding-bottom: 10px;
    }
    .columna1 {
        width: 90px;
        
    }
    .columna2{ 
        margin-left: 15px;
    }
    .cabeTable { 
        padding: 5px 0; 
    }
    
    .descargas {
        width: 100%; 
        display: inline-block;
    }
    .cajaTable,
    .cajaTable2{ 
        overflow-x: scroll;
    }
    .cuperoTable tr td:first-child{
        min-width: 90px;
    }
    .cuperoTable2 tr td:first-child,
    .cuperoTable2 tr td:last-child { 
        padding: 0 15px;
    }
    .descargas.w-100{
        display: inline-grid; 
        text-align: center; 
    }
   
}
@media (max-width: 991px){
   .paddingLeft{
       padding-left: 15px;
   }
   .paddingTop{
    padding-top: 50px;
    }
    .powered {
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        padding-bottom: 15px;
    }
    .btn-Solidez { 
        width: 100%;
    }
}
@media (max-width: 767px) {
    .btn-Home-Solidez-Boletas{
        width: 47%;
    }
}
@media (max-width: 660px) {
    
    .name-user,
    .name-admin { 
        font-size: 18px;
    }
    .titulo {
        font-size: 20px;
    }
    .subtitulo {
        font-size: 13px;
    }
    .bg-grayLigth{
        height: 100%;   
    }
    .imputsCampos2 input,
    .imputsCampos2 select,
    .imputsCampos input,
    .imputsCampos select{
        margin: 5px 0; 
    }
    .btnsOptions{
        width: 100%;
        padding: 0; 
        margin: 0;
    }
    .imputsCampos .labelFecha-1{
        margin-left: 0px;
    }
    .imputsCampos2 input,
    .imputsCampos2 select{
        margin-right: 0px;
    }
    .imputsCampos2 input,
    .imputsCampos2 select,
    .imputsCampos2 label,
    .imputsCampos input,
    .imputsCampos select,
    .imputsCampos label{
        width: 100%;
        margin-bottom: 0;
    }
    .imputsCampos2 label,
    .imputsCampos label{
        margin-top: 10px;
    }
    .btn-Solidez-3 {
        padding: 10px 20px; 
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
    .btnMobile {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
    .txtResultado {
        font-size: 16px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .titleUnidad { 
        text-align: center;
        width: 100%;
        font-size: 20px;
    }
    .iconosRecarga { 
        width: 100%;
        text-align: center;
        margin: 10px 0px;
    }
    .cuperoTable3 th, 
    .cuperoTable3 td {
        min-width: 118px;
        padding: 6px 10px;
    }

    .cuperoTable4 td  {
        min-width: 150px !important;
    }
    .cuperoTable4 td,
    .cuperoTable4 th{
        padding: 15px 0px !important;
    }
}
@media (max-width: 531px) {
    .btn-Home-Solidez-Boletas{
        width: 100%;
    }
}

.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;
  }
  
  .overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }

#busquedaMessage .react-datepicker-wrapper {
    width: auto;
}

.react-datepicker__close-icon {
    right: 5px;
}

#busquedaMessage .react-datepicker__close-icon {
    right: 25px;
}

#logo a {
    color: #555555;
}
#logo a:hover {
    color: #373435;
}

.botonesHome .badge {
    right: -10px;
    top: -10px;
}

.height500 {
    height: 530px;
}


ul.checkbox  { 
    margin: 0; 
    padding: 0; 
    margin-left: 20px; 
    list-style: none; 
  } 
  
  ul.checkbox li input { 
    margin-right: .25em; 
  } 
  
  ul.checkbox li { 
    border: 1px transparent solid; 
    display:inline-block;
    width:15em;
  } 
  
  ul.checkbox li label { 
    margin-left: 0.5rem;
  } 

  ul.checkbox li.focus  { 
    background-color: lightyellow; 
    border: 1px gray solid; 
    width: 15em; 
  } 

  div#root {
    overflow: hidden;
}

input.inputRespuestas {
    width: 90%;
}

.checkboxAll label {
    width: 100%;
}

.date-lanzamiento {
    width: auto;
    margin-top: 4px;
}

.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 200px;
}

.css-nhb8h9 {
    margin-bottom: 65px;
    float: right;
}

.css-1oj2twp-MuiPagination-root {
    margin-bottom: 65px;
    float: right;
}

.toggler-wrapper {
    width: 45px;
    height: 25px;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
  }
  
  .toggler-wrapper input[type="checkbox"] {
    display: none;
  }
  
  .toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
    background-color: #44cc66;
  }
  
  .toggler-wrapper .toggler-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .toggler-wrapper .toggler-knob {
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .toggler-wrapper.style-22 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
    left: calc(100% - 19px - 3px);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .toggler-wrapper.style-22 .toggler-slider {
    background-color: #eb4f37;
  }
  
  .toggler-wrapper.style-22 .toggler-knob {
    width: calc(25px - 6px);
    height: calc(25px - 6px);
    border-radius: 50%;
    left: 3px;
    top: 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: white;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 3rem;
    margin-bottom: 0.5rem;
}

.message{
    position: absolute;
 right: -10px;
 top:-15px;
 background-color: #dc3545;
 color: #fff;
 border-radius: 50px;
 padding: 5px 10px;
   }

   .style-password2 {
    padding-left: 0px;
    padding-right: 20px;
    top: 30px;
}

.imputsCampos3{
    width: 230px;
    margin-right: 20px;
}



.imputsCamposMostrar select{
    border: 1px solid #ced4da;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 6px;
    padding-right: 8px;
    color: #686868;
    background-color: #fff;
    background: url(../assets/images/down.svg) right/15px no-repeat;
    -webkit-appearance: none;
    background-position-x: 95%;
    outline: 0;
    width: 60px;
}